.calculator-input {
    background-color: transparent;
    border: none;
    border-style: none;
}

.calculator-input:focus {
    background-color: transparent;
    border: none;
    border-style: none;
    outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.web3modal-modal-card, .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-hitbox, #WEB3_CONNECT_MODAL_ID {
    position: relative;
    z-index: 9999 !important;
}